import React from "react"
import withLocation from "./withLocation"
import { StaticImage } from "gatsby-plugin-image"
import LogoOld from "../images/logo.svg"
import Stats from "./Stats"
import ConfigContext from "./utils/configContext"

class Header extends React.Component {
  static contextType = ConfigContext

  state = {
    isOpen: false,
    staticTags: this.context.staticTags || [],
    installUrl: "",
    loaded: false,
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      loaded: true,
    })
  }

  downloadApp = url => {
    const a = document.createElement("a")
    a.href = url
    a.download = url.split("/").pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      this.downloadApp(this.props.installUrl)
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
  }

  render() {
    let { tag1, tag2, tag3 } = this.props.search
    let tags = []
    tag1 && tags.push(tag1)
    tag2 && tags.push(tag2)
    tag3 && tags.push(tag3)

    let tagsArr = tags.length !== 0 ? tags : this.state.staticTags
    let { convertedTags, loaded } = this.state

    const LogoRummy = (
      <StaticImage
        src="../images/logo-rummy.png"
        alt="getmega"
        width={90}
        height={90}
      />
    )

    const LogoPoker = (
      <StaticImage
        src="../images/logo-poker.jpg"
        alt="getmega"
        width={90}
        height={90}
      />
    )

    const logoImage =
      this.context.gameCategory === "rummy" ? LogoRummy : LogoPoker

    const titleSection = (
      <>
        <p
          className="is-size-7 weight-500 developer"
          style={{ cursor: "pointer" }}
        >
          {this.context.gamesType ? this.context.gamesType : "MegaGames"}
        </p>
        <div className="developer">
          {loaded &&
            (this.context.cms && convertedTags ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: convertedTags,
                }}
              />
            ) : (
              <>
                {tagsArr.map(tag => (
                  <span key={tag} className="tag is-rounded">
                    {tag}
                  </span>
                ))}
              </>
            ))}
        </div>
      </>
    )

    return (
      <div className="header container">
        <div className="imageSection">
          <div
            className="logo"
            onClick={() => {
              this.onClickHandler("Logo Button")
            }}
            onKeyDown={() => {
              this.onClickHandler("Logo Button")
            }}
            role="button"
            tabIndex="0"
          >
            {this.context.oldLogo ? (
              <img src={LogoOld} alt="getmega" width="110" height="27" />
            ) : (
              logoImage
            )}
          </div>

          <div
            className="app-name"
            onClick={() => {
              this.onClickHandler("Title Button")
            }}
            onKeyDown={() => {
              this.onClickHandler("Title Button")
            }}
            role="button"
            tabIndex="0"
          >
            {titleSection}
          </div>
        </div>

        <div className="stats-container">
          <Stats
            fbEventAndShowModal={this.props.fbEventAndShowModal}
            togglePrompt={this.props.togglePrompt}
            toggleModal={this.props.toggleModal}
            isChrome={this.props.isChrome}
            installUrl={this.props.installUrl}
          />
        </div>

        <div
          className="install-button"
          onClick={() => {
            this.onClickHandler("Primary Install Button")
          }}
          onKeyDown={() => {
            this.onClickHandler("Primary Install Button")
          }}
          role="button"
          tabIndex="0"
        >
          <button size="is-small" className="weight-700">
            {this.context.installText || "Download Android App"}
          </button>
        </div>
        <div className="button-bottom-text">
          🔥Limited time offer- Play unlimited free cashgames
        </div>
      </div>
    )
  }
}

export default withLocation(Header)
