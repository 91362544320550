import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ConfigContext from "./utils/configContext"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default class Gallery extends React.Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.state = {}
  }

  downloadApp = url => {
    const a = document.createElement("a")
    a.href = url
    a.download = url.split("/").pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      this.downloadApp(this.props.installUrl)
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
    this.props.hideGallery()
  }

  componentDidMount() {
    this.slider.slickGoTo(this.props.slideToScroll)
  }

  render() {
    let { hideGallery } = this.props
    const gameCat = this.context.gameCategory
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
    }
    const bannerImages = graphql`
    {
      rummyBanners: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { glob: "banners/rummy/**" }
        }
        sort: {fields: name}
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 800, breakpoints: [ 400, 600 ])
            }
          }
        }
      }
      pokerBanners: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { glob: "banners/poker/**" }
        }
        sort: {fields: name}
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 280, breakpoints: [ 150])
            }
          }
        }
      }
    }
  `

    const InstallButton = (
      <div className="column has-text-centered">
        <button
          className="button-install has-text-weight-medium"
          onClick={() => {
            this.onClickHandler("Gallery Button")
          }}
        >
          {this.context.installText || "Download Android App"}
        </button>
      </div>
    )

    return (
      <>
        <div className="custom-modal gallery-container">
          <div
            className="gallery-close"
            onClick={hideGallery}
            onKeyDown={hideGallery}
            role="button"
            tabIndex="0"
          >
            {" "}
            <span className=" is-large">
              <strong style={{ color: "#fff" }} className="mdi ">
                X
              </strong>
            </span>
          </div>
          <div className="gallery-content">
            <div className={`gallery-slider ${this.context.gameCategory}`}>
              <StaticQuery
                query={bannerImages}
                render={data => {

                  const pageBanners = gameCat === "rummy" ? data.rummyBanners.edges : data.pokerBanners.edges
                  const bannerWidth = gameCat === "rummy" ? 400 : 250

                  return (
                    <Slider ref={slider => (this.slider = slider)} {...settings}>
                      {
                        pageBanners.map(
                          (item, i) => (
                            <div
                              key={i}>
                              <GatsbyImage image={item.node.childImageSharp.gatsbyImageData} alt={gameCat} width={bannerWidth} loading="auto" />
                            </div>
                          )
                        )
                      }
                    </Slider>
                  )
                }}
              />
            </div>
            {InstallButton}
          </div>
        </div>
      </>
    )
  }
}
