import React from "react"
import Header from "./Header"
import "../styles/buefy.css"
import "../styles/header.css"
import Carousel from "./Carousel"
import ReviewsList from "./ReviewsList"
import Faqs from "./Faqs"
import Popup from "./Popup"
import AppInfo from "./AppInfo"
import { generateUrl } from "./utils/generateUrl"
import ConfigContext from "./utils/configContext"
import DownloadHandler from "./DownloadHandler"
import Step1 from "../images/prompt/step1-rummy.png"
import Step2 from "../images/prompt/step2.png"
import Step3 from "../images/prompt/step3.png"
import Step4 from "../images/prompt/step4-rummy.png"

class App extends React.Component {
  static contextType = ConfigContext

  state = {
    reviews: [],
    reviewsByRating: {},
    isOpen: false,
    scrollHeight: 0,
    initialMount: true,
    installUrl: "",
    gameType: "",
    bannerOrder: "",
    showPrompt: false,
    chromeBrowser: false,
  }

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  fbEventAndShowModal = eventSource => {
    window?.fbq?.("trackCustom", "downloadButtonClicked", {
      click_source: eventSource,
    })
    window?.gtag?.("event", "Download APK", {
      event_category: "Downloaded",
      event_label: eventSource,
      value: 1,
    })
    window?.trackGLDownloadEvent?.()
  }

  async componentDidMount() {
    let self = this
    this.setState({
      ...this.state,
      installUrl: generateUrl(this.context.installUrl, this.context.forceUse),
    })

    window.onscroll = function () {
      if (self.state.initialMount && window.pageYOffset > 5) {
        self.setState({ scrollHeight: window.pageYOffset, initialMount: false })
      }
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 200 && this.state.progress !== 200) {
        this.setState({ progress: 200 })
      }
      if (window.pageYOffset < 200 && this.state.progress !== 0) {
        this.setState({ progress: 0 })
      }
    })

    const { search } = window.location
    const params = new URLSearchParams(search)
    const pid = params.get("pid")

    if (pid !== "taboola_int") {
      // open free gems popup after 30 seconds
      const popupTimer = setTimeout(() => {
        this.setState({ isPopupOpen: true })
      }, 5 * 1000)
      this.setState({ freeGemsTimer: popupTimer })
    }

    // check for utm_game in url
    const siteurl = new URL(window.location.href)
    const checkGame = siteurl.searchParams.get("utm_game")
    if (checkGame) {
      this.setState({ gameType: checkGame, gameName: "landing-" + checkGame })
    }
    const getBannerOrder = siteurl.searchParams.get("ad_order")
    if (getBannerOrder) {
      this.setState({ bannerOrder: getBannerOrder })
    }
  }

  componentWillUnmount() {
    window.onscroll = null
    if (this.state.freeGemsTimer) {
      clearTimeout(this.state.freeGemsTimer)
    }
  }

  downloadApp = url => {
    const a = document.createElement("a")
    a.href = url
    a.download = url.split("/").pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  nonChromeFunc = () => {
    this.toggleModal()
    setTimeout(() => {
      this.downloadApp(this.state.installUrl)
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.fbEventAndShowModal(eventSource)
    this.state.chromeBrowser ? this.togglePrompt() : this.nonChromeFunc()
  }

  filterReviews = filter => {
    let { reviewsByRating, reviews } = this.state
    let filteredReviews
    let remainingReviews

    filteredReviews =
      (reviewsByRating[filter.toString()] && [
        ...reviewsByRating[filter.toString()],
      ]) ||
      []

    remainingReviews = reviews.filter(
      review => review.stars !== parseInt(filter)
    )

    filteredReviews = [...filteredReviews, ...remainingReviews]
    this.setState({ reviews: filteredReviews })
  }

  closePopup = () => {
    this.setState({ isPopupOpen: false })
  }

  togglePrompt = () => {
    this.setState(state => ({ showPrompt: !state.showPrompt }))
  }

  promptCallback = () => {
    this.togglePrompt()
    if (
      this.context.showDownloadVideo === undefined ||
      this.context.showDownloadVideo === true
    )
      this.toggleModal()
  }

  render() {
    let {
      scrollHeight,
      isPopupOpen,
      progress,
      installUrl,
      gameType,
      gameName,
      bannerOrder,
      chromeBrowser,
    } = this.state
    let transitionClass = progress >= 200 ? "fade-in" : "fade-out"
    return (
      <>
        {this.state.showPrompt ? (
          <DownloadHandler
            togglePrompt={this.togglePrompt}
            installUrl={installUrl}
            callback={this.promptCallback}
          />
        ) : null}
        {this.state.isOpen ? (
          <div className="custom-modal video-modal">
            {this.context.gameCategory === "rummy" ? (
              <div className="steps-container">
                <div className="steps-heading">
                  How to Install GetMega Rummy App
                </div>
                <div className="steps-item">
                  <div className="step-title">step 1</div>
                  <div className="step-content">
                    <img src={Step1} alt="step1" />
                    <div className="step-text">
                      Click the <strong>Download anyway</strong> button
                    </div>
                  </div>
                </div>
                <div className="steps-item">
                  <div className="step-title">step 2</div>
                  <div className="step-content">
                    <img src={Step2} alt="step1" />
                    <div className="step-text">
                      Click <strong>Open</strong>, on File downloaded, and click
                      on Settings
                    </div>
                  </div>
                </div>
                <div className="steps-item">
                  <div className="step-title">step 3</div>
                  <div className="step-content">
                    <img src={Step3} alt="step1" />
                    <div className="step-text">
                      Tap on '<strong>Allow from this source</strong>' and click
                      on the mobile back button.
                    </div>
                  </div>
                </div>
                <div className="steps-item">
                  <div className="step-title">step 4</div>
                  <div className="step-content">
                    <img src={Step4} alt="step1" />
                    <div className="step-text">
                      Click on '<strong>Install</strong>' and start playing.
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <iframe
                src={`https://player.vimeo.com/video/${this.context.vimeoVideoId}?title=0&byline=0&portrait=0&autoplay=1&loop=1&background=1`}
                width="640"
                height="1138"
                frameBorder="0"
                allowFullScreen={true}
                title="install video"
              />
            )}
            <span
              className="is-large"
              onClick={this.toggleModal}
              onKeyDown={this.toggleModal}
              role="button"
              tabIndex="0"
            >
              <strong style={{ color: "#fff" }} className="mdi ">
                X
              </strong>
            </span>
          </div>
        ) : null}
        <div id="app" className={`${this.state.isOpen ? "no-scrolling" : ""}`}>
          <Header
            className="container"
            fbEventAndShowModal={this.fbEventAndShowModal}
            togglePrompt={this.togglePrompt}
            toggleModal={this.toggleModal}
            gameName={gameName}
            isChrome={chromeBrowser}
            installUrl={installUrl}
          />
          <hr className="bg-transparent" />

          <Carousel
            fbEventAndShowModal={this.fbEventAndShowModal}
            togglePrompt={this.togglePrompt}
            toggleModal={this.toggleModal}
            gameName={gameName}
            bannerOrder={bannerOrder}
            isChrome={chromeBrowser}
            installUrl={installUrl}
          />
          <hr />
          <ReviewsList
            gameName={gameType || this.context.staticReviews}
            scrollHeight={scrollHeight}
          />
          {this.context.faqsVisible && <Faqs />}
          {this.context.showAppInfo && (
            <>
              <hr />
              <AppInfo />
            </>
          )}
        </div>

        <div
          className={`install-button-bottom weight-700 sticky-install-button ${transitionClass}`}
          onClick={() => {
            this.onClickHandler("Sticky Install Button")
          }}
          onKeyDown={() => {
            this.onClickHandler("Sticky Install Button")
          }}
          role="button"
          tabIndex="0"
        >
          {this.context.installText || "Download Android App"}
        </div>

        {isPopupOpen && !this.state.isOpen ? (
          <Popup
            closePopup={this.closePopup}
            togglePrompt={this.togglePrompt}
            toggleModal={this.toggleModal}
            installUrl={installUrl}
            isChrome={chromeBrowser}
            fbEventAndShowModal={this.fbEventAndShowModal}
          />
        ) : null}
      </>
    )
  }
}

export default App
